import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nfts from '../assets/about.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="aboutMain" data-aos="fade-up" data-aos-duration="1000">
                <div class="storyH">
                                ABOUT US
                            </div>

                            
                    <div class="about">
                    
                        <img src={nfts} />
                        <div class="storyCon">
                           
                            <p class="storyCon3">Welcome to Collateral Capital - the first and only decentralized equity fund fully traded by industry professionals on futures crypto markets.</p>
                            <p class="storyCon3">Our unique approach allows us to generate funds from NFT minting and add them to our team's futures portfolio. At the end of every week, profits are distributed to NFT holders according to their equity value</p>
                            <p class="storyCon3">Our team has years of experience in generating wealth using edged trading strategies, and we project an average return on investment of 4.5% weekly. While the minimum guaranteed return is 1% weekly, extreme market volatility can see the ROI soar up to 15% weekly, regardless of the market's direction.</p>
                            <p class="storyCon3">At Collateral Capital, we take pride in our exclusive, extremely limited supply. We want to limit our footprint on the market and keep our holder's circle exclusive. Join us today to experience the benefits of our innovative approach to investment.</p>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default Story;

