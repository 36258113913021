import React, { Component } from 'react';

let url = "https://youtu.be/vfdP0-ovTg8";

class Video extends Component {



  render() {

    return (

      <div class="videoContainer" data-aos="fade-up" data-aos-duration="1000">

        <iframe class="video" src="https://www.youtube.com/embed/vfdP0-ovTg8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>      </div>)
  }
}

export default Video;