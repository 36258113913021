import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft1 from '../assets/NFTs CC-02.png'
import nft2 from '../assets/Asset 23@2x.png'

let nft = "https://media.discordapp.net/attachments/1008571037862080542/1100428832621744279/NFTHouse9_shiba_inu_cyber_punk_theme_d45df8d0-051b-4629-b29b-16ac278bc798.png?width=418&height=418"

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class NFTproject extends Component {

    render() {
        return (

            <div class="boxWrap2NFT">

                <div class="aboutMain" data-aos="fade-up" data-aos-duration="1000">
                    <div class="storyH2New">
                        THE NFTs
                    </div>
                
                    <div class="teamH">
                        THE NFTs
                    </div>
                    <div class="about3">
                        <div class="boxMain">
                            <img class="box1NFT" src={nft1} data-aos="zoom-in" data-aos-duration="1000" />

                            <div class="boxMain">
                                <div class="box1" data-aos="zoom-in" data-aos-duration="1000">
                                    <div class="boxCon"><div class="nftT">CUB NFT</div>

                                        <div class="coloumn1">
                                            <div class="row1">
                                                <div class="figureSection">
                                                    <div class="figure1">120</div>
                                                    <div class="figure2">USDT minting cost</div>
                                                </div>

                                                <div class="figureSection">
                                                    <div class="figure1">100</div>
                                                    <div class="figure2">USDT equity</div>
                                                </div>
                                            </div>

                                            <div class="row1">

                                                <div class="figureSection">
                                                    <div class="figure1">7000</div>
                                                    <div class="figure2">Total supply</div>
                                                </div>

                                                <div class="figureSection">
                                                    <div class="figure1">2</div>
                                                    <div class="figure2">Additional perks</div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <img class="box1NFTMob" src={nft1} data-aos="zoom-in" data-aos-duration="1000" />

                                </div>
                            </div>
                        </div>
                        <div class="boxMain">
                            <img class="box1NFT" src={nft2} data-aos="zoom-in" data-aos-duration="1000" />

                            <div class="boxMain">
                                <div class="box1" data-aos="zoom-in" data-aos-duration="1000">
                                    <div class="boxCon"><div class="nftT2">ALPHA NFT</div>

                                        <div class="coloumn1">
                                            <div class="row1">
                                                <div class="figureSection">
                                                    <div class="figure1">365</div>
                                                    <div class="figure2">USDT minting cost</div>
                                                </div>

                                                <div class="figureSection">
                                                    <div class="figure1">350</div>
                                                    <div class="figure2">USDT equity</div>
                                                </div>
                                            </div>

                                            <div class="row1">

                                                <div class="figureSection">
                                                    <div class="figure1">2000</div>
                                                    <div class="figure2">Total supply</div>
                                                </div>

                                                <div class="figureSection">
                                                    <div class="figure1">4</div>
                                                    <div class="figure2">Additional perks</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <img class="box1NFTMob" src={nft2} data-aos="zoom-in" data-aos-duration="1000" />

                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        )
    }
}

export default NFTproject;

