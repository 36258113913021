import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import doc from '../assets/doc.png';
import twitter from '../assets/twitter (2).png';
 
const docLink = () => {
	window.open("https://drive.google.com/file/d/1Pik6ASOs2l3ZhrbzdXt5yLyFYKxUtajU/view?usp=sharing");
}

const tweet = () => {
	window.open("#");
}


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">
                <div class="copyright">© 2023 Collateral Capital</div>

                     <div class="icons">
                        <div class="discord"><img class="osPic" src={twitter} /></div>
                        <div class="discord"><img class="osPic" onClick={docLink} src={doc} /></div>
                     </div>

                </div>

            </div>
        )
    }
}

export default Footer;

