import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nfts from '../assets/perks.png';
import { LibraryAdd } from '@material-ui/icons';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Perks extends Component {

    render() {
        return (

            <div class="perks">
                <div class="aboutMain" data-aos="fade-up" data-aos-duration="1000">
                <div class="storyH2New">
                        PERKS
                    </div>
                    <div class="about">

                        <img src={nfts} />
                        <div class="perksMain">
                        <div class="storyH3">
                        PERKS
                    </div>
                            <p class="storyCon3Hl">Different tier of NFT will unlock more perks </p>
                            <li class="perksCon">Equity bearing NFT paying profit weekly.</li>
                            <li class="perksCon">Private discord access with full view of active trades and overall PnL.</li>
                            <li class="perksCon">Access to live tracking of portfolio growth.</li>
                            <li class="perksCon">Private access to trading signals on many USDT pairs.</li>
                            <li class="perksCon">Private access to copy trading.</li>
                            <li class="perksCon">Weekly mentorship and 1 on 1 training: with our most profitable traders.</li>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default Perks;

