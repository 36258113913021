import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nfts from '../assets/team.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Team extends Component {

    render() {
        return (

            <div class="perks">
                <div class="aboutMain" data-aos="fade-up" data-aos-duration="1000">
                <div class="storyH2New">
                        TEAM
                    </div>
                    <div class="about">
                        
                        <div class="storyCon">
                        <div class="teamH">
                        TEAM
                    </div>
                            <p class="teamCon">At Collateral Capital, we take pride in our team's expertise and experience. Our team has many decades of cumulative experience in both traditional and crypto markets, and we are proud to have some of the best traders in the industry working with us. In fact, some of our traders are still working full-time or consulting for some of the largest hedge fund, financial institutions, and equity leading market making firms around the USA and Europe.
                            </p>
                            <p class="teamCon">We understand that transparency is important to our investors, and that's why we only disclose the identity of our traders to those who hold our Liquid Leverage NFT. We provide a non-disclosure agreement to our members who wish to receive KYC information from our trading team, as well as the KYC information provided to the centralized exchanges used by Liquid Leverage.
                            </p>
                            <p class="teamCon">Our team is composed of nine traders, four research analysts, and four technical analysts, all of whom work together to ensure our investors get the best possible returns. Our team has a proven track record of generating wealth using edged trading strategies, and we're excited to bring that expertise to the world of NFT investment.
                            </p>
                            <p class="teamCon">Join us today to experience the benefits of our team's decades of experience and innovative investment approach. </p>
                        </div>
                        <img src={nfts} />
                        <p class="teamCon2">At Collateral Capital, we take pride in our team's expertise and experience. Our team has many decades of cumulative experience in both traditional and crypto markets, and we are proud to have some of the best traders in the industry working with us. In fact, some of our traders are still working full-time or consulting for some of the largest hedge fund, financial institutions, and equity leading market making firms around the USA and Europe.
                            </p>
                            <p class="teamCon2">We understand that transparency is important to our investors, and that's why we only disclose the identity of our traders to those who hold our Liquid Leverage NFT. We provide a non-disclosure agreement to our members who wish to receive KYC information from our trading team, as well as the KYC information provided to the centralized exchanges used by Liquid Leverage.
                            </p>
                            <p class="teamCon2">Our team is composed of nine traders, four research analysts, and four technical analysts, all of whom work together to ensure our investors get the best possible returns. Our team has a proven track record of generating wealth using edged trading strategies, and we're excited to bring that expertise to the world of NFT investment.
                            </p>
                            <p class="teamCon2">Join us today to experience the benefits of our team's decades of experience and innovative investment approach. </p>
                    </div>


                </div>
            </div>
        )
    }
}

export default Team;

