import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import '../App.css';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import back from '../assets/undo.png';

const docLink = () => {
    window.open("https://drive.google.com/file/d/1Pik6ASOs2l3ZhrbzdXt5yLyFYKxUtajU/view?usp=sharing");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let presale_status = false;
let mintPic = "https://media.discordapp.net/attachments/1008571037862080542/1100463311583199253/NFTHouse9_shiba_inu_cyber_punk_theme_57122e0c-38b7-48b6-b7d5-d21e4a45c642.png?width=418&height=418";
let contractZksync;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "values",
                "type": "uint256[]"
            }
        ],
        "name": "TransferBatch",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "TransferSingle",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "value",
                "type": "string"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "URI",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "MAX_SUPPLY",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "alreadyMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "accounts",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            }
        ],
        "name": "balanceOfBatch",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deleteDefaultRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "exists",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "max_ids",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "max_per_wallet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "mintBatch",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicSaleCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "public_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "resetTokenRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeBatchTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "name": "setContractURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            },
            {
                "internalType": "uint96",
                "name": "_feeNumerator",
                "type": "uint96"
            }
        ],
        "name": "setDefaultRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_MAX_SUPPLY",
                "type": "uint256"
            }
        ],
        "name": "setMAX_SUPPLY",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_max_ids",
                "type": "uint256"
            }
        ],
        "name": "setMax_ids",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_max_per_wallet",
                "type": "uint256"
            }
        ],
        "name": "setMax_per_wallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_publicSaleCost",
                "type": "uint256"
            }
        ],
        "name": "setPublicSaleCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            },
            {
                "internalType": "uint96",
                "name": "_royaltyFeesInBips",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyInfo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            },
            {
                "internalType": "uint96",
                "name": "_feeNumerator",
                "type": "uint96"
            }
        ],
        "name": "setTokenRoyalty",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "newuri",
                "type": "string"
            }
        ],
        "name": "setURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_public_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            }
        ],
        "name": "uri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }
];

const address = "0x0636fbd5a3f93B76bAE3D177157D217441698783";
let contract;


class Mint extends Component {

    state = {
        walletAddress: "",
        totalSupply: "",
        currentPrice: "",
        nextPrice: "",
        nextSessionAmount: "",
        onlyLeftAmount: "",
        statusError: false,
        statusLoading: false,
        success: false,
        nftMintingAmount: 1,
        totalValue: "",
        presaleValue: "0",
        maxmint: '',
        _adminPanel: 0,
        _adminWhitelistAddresses: [],
        _adminWhitelistAddresses2: [],
        _maxTokensToBuy: "",
        _testingValue: '',
        _wlMint: '',
        _FinalResult: '',
        _wlMsg: 'Whitelist Mint',
        _public_sale_cost: '',
        _presalesale_cost: '',
        _MAX_PER_WALLET: '',
        _public_mint_status: '',
        _presale_status: '',
        _cost: '',
        _mintStatus: false,
        gallery: '',
        _navbarOpen: 0,
        _greeting: null,
        _provider: null,
        _signer: null,
        _contractZksync: null
    }

    /*async initializeProviderAndSigner() {
        const provider = new Provider('https://testnet.era.zksync.dev');
        // Note that we still need to get the Metamask signer
        const signer = (new Web3Provider(window.ethereum).getSigner)();
        contractZksync = new Contract(
            address,
            ABI,
            signer
        );
        this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
        console.log("provider : " + provider);
        console.log("signer : " + signer);
        console.log("contractZksync : " + contractZksync);
    }/*

    async getGreeting() {
        const greeting = await this.state.contract.greet();
        this.setState({ greeting });
    }

    /*async getOverrides() {
        if (this.selectedToken.l1Address != ETH_L1_ADDRESS) {
          const testnetPaymaster =
            await this.provider.getTestnetPaymasterAddress();
      
          const gasPrice = await this.provider.getGasPrice();
      
          // estimate gasLimit via paymaster
          const paramsForFeeEstimation = utils.getPaymasterParams(
            testnetPaymaster,
            {
              type: "ApprovalBased",
              minimalAllowance: ethers.BigNumber.from("1"),
              token: this.selectedToken.l2Address,
              innerInput: new Uint8Array(),
            }
          );
      
          // estimate gasLimit via paymaster
          const gasLimit = await this.contract.estimateGas.setGreeting(
            this.newGreeting,
            {
              customData: {
                gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
                paymasterParams: paramsForFeeEstimation,
              },
            }
          );
      
          const fee = gasPrice.mul(gasLimit.toString());
      
          const paymasterParams = utils.getPaymasterParams(testnetPaymaster, {
            type: "ApprovalBased",
            token: this.selectedToken.l2Address,
            minimalAllowance: fee,
            // empty bytes as testnet paymaster does not use innerInput
            innerInput: new Uint8Array(),
          });
      
          return {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: ethers.BigNumber.from(0),
            gasLimit,
            customData: {
              gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
              paymasterParams,
            },
          };
        }
      
        return {};
      }*/

    closeNav = () => {
        const navBar = document.querySelector(".littleNav");
        navBar.classList.add("hidden");
    }

    closeNav = async event => {
        event.preventDefault();

        this.setState({ _navbarOpen: 0 });
    }

    async componentDidMount() {

        try {

            if (localStorage?.getItem('src') == "1") {
                localStorage.setItem('src', "2");

                this.setState({ gallery: "1" });

            } else

                if (localStorage?.getItem('src') == "2") {
                    localStorage.setItem('src', "3");

                    this.setState({ gallery: "2" });

                } else

                    if (localStorage?.getItem('src') == "3") {
                        localStorage.setItem('src', "4");

                        this.setState({ gallery: "3" });

                    } else

                        if (localStorage?.getItem('src') == "4") {
                            localStorage.setItem('src', "1");

                            this.setState({ gallery: "4" });

                        } else {

                            localStorage.setItem('src', "1");
                            this.setState({ gallery: "1" });

                        }


        } catch (err) {
            console.log(err);
        }

        if (localStorage?.getItem('isWalletConnected') === 'true') {
            try {
                const providerOptions = {
                    walletconnect: {
                        package: WalletConnectProvider, // required
                        options: {
                            infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
                        }
                    },
                    /*coinbasewallet: {
                        package: CoinbaseWalletSDK, // Required
                        options: {
                            appName: "Aterium Universe", // Required
                            infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
                            rpc: "", // Optional if `infuraId` is provided; otherwise it's required
                            chainId: 1, // Optional. It defaults to 1 if not provided
                            darkMode: true // Optional. Use dark theme, defaults to false
                        }
                    }*/
                };

                const web3Modal = new Web3Modal({
                    network: "mainnet", // optional
                    cacheProvider: true, // optional
                    providerOptions // required
                });



                const provider = await web3Modal.connect();

                //  Enable session (triggers QR Code modal)
                await provider.enable();

                const web3 = new Web3(provider);
                console.log("provider : " + provider);
                // Subscribe to accounts change
                provider.on("accountsChanged", (accounts) => {
                    console.log(accounts);
                });

                // Subscribe to chainId change
                provider.on("chainChanged", (chainId) => {
                    console.log(chainId);
                });

                // Subscribe to provider connection
                provider.on("connect", (info) => {
                    console.log(info);
                    console.log("I'm LOGGED IN");
                });

                // Subscribe to provider disconnection
                provider.on("disconnect", (error) => {
                    console.log(error);
                });

                // test if wallet is connected
                if (web3Modal.cachedProvider) {
                    // connected now you can get accounts
                    console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
                    console.log("provider :" + provider);

                    const accounts = await web3.eth.getAccounts();

                    account = accounts[0];
                    this.setState({ walletAddress: account });

                    //const signer = (new Web3Provider(window.ethereum).getSigner)();

                    contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);

                    if (provider) {


                        //	(async () => {

                        if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

                            const chainId = 324;

                            if (window.ethereum.networkVersion !== chainId) {
                                try {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: web3.utils.toHex(chainId) }],
                                    });
                                } catch (err) {
                                    // This error code indicates that the chain has not been added to MetaMask.
                                    if (err.code === 4902) {
                                        await window.ethereum.request({
                                            method: 'wallet_addEthereumChain',
                                            params: [
                                                {
                                                    /*chainName: 'zkSync alpha testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

                                                    chainName: 'zkSync Era Mainnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://mainnet.era.zksync.io'],

                                                    /*chainName: 'Rinkeby Test Network',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
                                                    /*chainName: 'Mumbai Testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                                    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                                                },
                                            ],
                                        });
                                    }
                                }
                            }


                            try {

                                try {
                                    localStorage.setItem('isWalletConnected', true);
                                } catch (ex) {
                                    console.log(ex)
                                }

                                totalSupplyNFT = await contract.methods.totalSupply(0).call();
                                this.setState({ totalSupply: totalSupplyNFT });
                                console.log("Total Supply:" + totalSupplyNFT);

                                publicSale = await contract.methods.balanceOf(account, 0).call();
                                this.setState({ myNFTWallet: publicSale });

                                public_sale_cost = await contract.methods.publicSaleCost().call();
                                this.setState({ _public_sale_cost: public_sale_cost });
                                console.log("public_sale_cost :" + public_sale_cost);
                                this.setState({ _cost: public_sale_cost });
                                this.setState({ _mintStatus: false });

                                MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                                this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                                console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                                public_mint_status = await contract.methods.public_mint_status().call();
                                this.setState({ _public_mint_status: public_mint_status });
                                console.log("public_mint_status :" + public_mint_status);

                                owner = await contract.methods.owner().call();
                                console.log("Owner" + owner);
                                //..........................................................................//
                                if (owner == account) {
                                    console.log("owner : " + owner)
                                    onlyLeft = 41000 - totalSupplyNFT;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }

                                    valueOfNFTs = mintAmount * 0;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                } else {
                                    mintAmount = 1;

                                    if (totalSupplyNFT == 41000) {

                                        onlyLeft = 41000 - totalSupplyNFT;
                                        mintAmount = onlyLeft;
                                        this.setState({ msg: "SOLD OUT" });

                                    } else {
                                        mintAmount = 1;
                                        onlyLeft = MAX_PER_WALLET - publicSale;

                                        if (mintAmount > onlyLeft) {
                                            mintAmount = onlyLeft;
                                        }
                                        //mintAmount = onlyLeft;

                                        valueOfNFTs = mintAmount * this.state._cost;
                                        wMintAmount = mintAmount;


                                        this.setState({ nftMintingAmount: mintAmount });

                                        this.setState({ totalValue: valueOfNFTs });
                                    }
                                }
                                ///................................///



                                //..........................................................................//

                            }
                            catch (err) {
                                console.log("err: " + err);

                            }

                        } else if (web3Modal.cachedProvider == "walletconnect") {

                            const chainId = 324;

                            if (WalletConnectProvider.networkVersion !== chainId) {
                                try {
                                    await WalletConnectProvider.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: web3.utils.toHex(chainId) }],
                                    });
                                } catch (err) {
                                    // This error code indicates that the chain has not been added to MetaMask.
                                    if (err.code === 4902) {
                                        await window.ethereum.request({
                                            method: 'wallet_addEthereumChain',
                                            params: [
                                                {
                                                    /*chainName: 'zkSync alpha testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

                                                    chainName: 'zkSync Era Mainnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://mainnet.era.zksync.io'],

                                                    /*chainName: 'Mumbai Testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
                                                    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
                                                },
                                            ],
                                        });
                                    }
                                }
                            }


                            try {

                                try {
                                    localStorage.setItem('isWalletConnected', true);
                                } catch (ex) {
                                    console.log(ex)
                                }

                                totalSupplyNFT = await contract.methods.totalSupply(0).call();
                                this.setState({ totalSupply: totalSupplyNFT });
                                console.log("Total Supply:" + totalSupplyNFT);

                                publicSale = await contract.methods.balanceOf(account, 0).call();
                                this.setState({ myNFTWallet: publicSale });

                                public_sale_cost = await contract.methods.publicSaleCost().call();
                                this.setState({ _public_sale_cost: public_sale_cost });
                                console.log("public_sale_cost :" + public_sale_cost);
                                this.setState({ _cost: public_sale_cost });
                                this.setState({ _mintStatus: false });

                                MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                                this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                                console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                                public_mint_status = await contract.methods.public_mint_status().call();
                                this.setState({ _public_mint_status: public_mint_status });
                                console.log("public_mint_status :" + public_mint_status);

                                owner = await contract.methods.owner().call();
                                console.log("Owner" + owner);
                                //..........................................................................//
                                if (owner == account) {
                                    console.log("owner : " + owner)
                                    onlyLeft = 41000 - totalSupplyNFT;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }

                                    valueOfNFTs = mintAmount * 0;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                } else {
                                    mintAmount = 1;

                                    if (totalSupplyNFT == 41000) {

                                        onlyLeft = 41000 - totalSupplyNFT;
                                        mintAmount = onlyLeft;
                                        this.setState({ msg: "SOLD OUT" });

                                    } else {
                                        mintAmount = 1;
                                        onlyLeft = MAX_PER_WALLET - publicSale;

                                        if (mintAmount > onlyLeft) {
                                            mintAmount = onlyLeft;
                                        }
                                        //mintAmount = onlyLeft;

                                        valueOfNFTs = mintAmount * this.state._cost;
                                        wMintAmount = mintAmount;


                                        this.setState({ nftMintingAmount: mintAmount });

                                        this.setState({ totalValue: valueOfNFTs });
                                    }
                                }
                                ///................................///



                                //..........................................................................//

                            } catch (err) {
                                console.log("err: " + err);

                            }


                        }
                        //})();

                        //.....................................................................//

                        // Legacy providers may only have ethereum.sendAsync
                        const chainId = await provider.request({
                            method: 'eth_chainId'
                        })

                    } else {

                        // if the provider is not detected, detectEthereumProvider resolves to null
                        console.error('Please install a Valid Wallet');
                        alert('A valid provider could not be found!');

                    }

                }
            } catch (ex) {
                console.log(ex);
            }
        } else {

            console.log("NOT CONNECTED");

        }

    }

    onSubmitMinus = async event => {
        event.preventDefault();

        mintAmount = mintAmount - 1;

        if (mintAmount < 1) {
            mintAmount = 1
        }

        if (owner == account) {
            console.log("owner : " + owner)
            onlyLeft = 41000 - totalSupplyNFT;

            if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
            }

            valueOfNFTs = mintAmount * 0;



            this.setState({ nftMintingAmount: mintAmount });

            this.setState({ totalValue: valueOfNFTs });
        } else {
            if (totalSupplyNFT < 41000) {

                onlyLeft = MAX_PER_WALLET - publicSale;

                if (mintAmount > onlyLeft) {
                    mintAmount = onlyLeft;
                }
                valueOfNFTs = mintAmount * this.state._cost;
                wMintAmount = mintAmount;


                this.setState({ nftMintingAmount: mintAmount });
                console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

                this.setState({ totalValue: valueOfNFTs });
            }
        }

    }

    onSubmitPlus = async event => {
        event.preventDefault();

        //,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
        mintAmount = mintAmount + 1;

        if (owner == account) {
            console.log("owner : " + owner)
            onlyLeft = 1000 - totalSupplyNFT;

            if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
            }

            valueOfNFTs = mintAmount * 0;



            this.setState({ nftMintingAmount: mintAmount });

            this.setState({ totalValue: valueOfNFTs });
        } else {

            if (totalSupplyNFT < 41000) {

                onlyLeft = MAX_PER_WALLET - publicSale;
                console.log(onlyLeft);

                if (mintAmount > onlyLeft) {
                    mintAmount = onlyLeft;
                }
                valueOfNFTs = mintAmount * this.state._cost;
                wMintAmount = mintAmount;

                this.setState({ nftMintingAmount: mintAmount });
                console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

                this.setState({ totalValue: valueOfNFTs });
            }
        }

    }


    onSubmit2 = async event => {
        event.preventDefault();

        console.log(this.state.walletAddress);

        try {
            let owner = await contract.methods.owner().call();


            if (account != owner) {


                try {

                    console.log("minAmount:" + mintAmount);
                    console.log("valueOfNFTs:" + valueOfNFTs);

                    this.setState({ statusError: false, statusLoading: true });
                    await contract.methods.mint(0, this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * this.state._cost });

                    this.setState({ statusLoading: false, success: true });
                    console.log("Mint Amount :" + this.state.mintAmount);
                    window.location.reload(true);


                } catch (err) {
                    this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                    console.log(err);


                }
            } else {

                try {


                    console.log("minAmount:" + mintAmount);
                    console.log("valueOfNFTs:" + valueOfNFTs);


                    this.setState({ statusError: false, statusLoading: true });
                    const tx = await contract.methods.mint(0, this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * 0 });


                    this.setState({ statusLoading: false, success: true });
                    console.log("Mint Amount :" + this.state.mintAmount);
                    window.location.reload(true);


                } catch (err) {
                    this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                    console.log(err);

                }

            }
        } catch (err) {

            console.log(err);

        }
    }


    walletConnect = async event => {
        event.preventDefault();

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "bf933c3446b3464c988114813a1360ac" // required
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });

        const provider = await web3Modal.connect();

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        const web3 = new Web3(provider);
        console.log("provider : " + provider);
        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
            console.log(chainId);
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
            console.log(info);
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
            console.log(error);
        });

        // test if wallet is connected
        if (web3Modal.cachedProvider) {
            // connected now you can get accounts
            console.log("provider :" + web3Modal.cachedProvider);
            const accounts = await web3.eth.getAccounts();

            account = accounts[0];
            this.setState({ walletAddress: account });
            //const signer = (new Web3Provider(window.ethereum).getSigner)();

            contract = new web3.eth.Contract(ABI, address);
            console.log("contract :" + contract);
            //const provider = new Provider('https://testnet.era.zksync.dev');
            // Note that we still need to get the Metamask signer
            /*const signer = (new Web3Provider(window.ethereum).getSigner)();
            contractZksync = new web3.eth.Contract(
                ABI,
                address,
                signer
            );
            this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
            console.log("provider : " + provider);
            console.log("signer : " + signer);
            console.log("contractZksync : " + contractZksync);*/

            if (provider) {


                (async () => {


                    if (web3Modal.cachedProvider != "walletconnect") {

                        const chainId = 324;

                        if (window.ethereum.networkVersion !== chainId) {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(chainId) }],
                                });
                            } catch (err) {
                                // This error code indicates that the chain has not been added to MetaMask.
                                if (err.code === 4902) {
                                    await window.ethereum.request({
                                        method: 'wallet_addEthereumChain',
                                        params: [
                                            {
                                                /*chainName: 'Smart ChainChain',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
                                                rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

                                                /*chainName: 'zkSync alpha testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

                                                chainName: 'zkSync Era Mainnet',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
                                                rpcUrls: ['https://mainnet.era.zksync.io'],

                                                /*chainName: 'Goerli Test Network',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
                                                rpcUrls: ['https://goerli.infura.io/v3/'],*/
                                            },
                                        ],
                                    });
                                }
                            }
                        }





                        try {

                            try {
                                localStorage.setItem('isWalletConnected', true);
                            } catch (ex) {
                                console.log(ex)
                            }

                            totalSupplyNFT = await contract.methods.totalSupply(0).call();
                            this.setState({ totalSupply: totalSupplyNFT });
                            console.log("Total Supply:" + totalSupplyNFT);

                            publicSale = await contract.methods.balanceOf(account, 0).call();
                            this.setState({ myNFTWallet: publicSale });

                            public_sale_cost = await contract.methods.publicSaleCost().call();
                            this.setState({ _public_sale_cost: public_sale_cost });
                            console.log("public_sale_cost :" + public_sale_cost);
                            this.setState({ _cost: public_sale_cost });
                            this.setState({ _mintStatus: false });

                            MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                            this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                            console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                            public_mint_status = await contract.methods.public_mint_status().call();
                            this.setState({ _public_mint_status: public_mint_status });
                            console.log("public_mint_status :" + public_mint_status);

                            owner = await contract.methods.owner().call();
                            console.log("Owner" + owner);
                            //..........................................................................//
                            if (owner == account) {
                                console.log("owner : " + owner)
                                onlyLeft = 41000 - totalSupplyNFT;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }

                                valueOfNFTs = mintAmount * 0;
                                wMintAmount = mintAmount;


                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            } else {
                                mintAmount = 1;

                                if (totalSupplyNFT == 41000) {

                                    onlyLeft = 41000 - totalSupplyNFT;
                                    mintAmount = onlyLeft;
                                    this.setState({ msg: "SOLD OUT" });

                                } else {
                                    mintAmount = 1;
                                    onlyLeft = MAX_PER_WALLET - publicSale;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }
                                    //mintAmount = onlyLeft;

                                    valueOfNFTs = mintAmount * this.state._cost;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                }
                            }
                            ///................................///



                            //..........................................................................//

                        } catch (err) {
                            console.log("err: " + err);

                        }
                    } else {

                        const chainId = 324;

                        if (WalletConnectProvider.networkVersion !== chainId) {
                            try {
                                await WalletConnectProvider.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(chainId) }],
                                });
                            } catch (err) {
                                // This error code indicates that the chain has not been added to MetaMask.
                                if (err.code === 4902) {
                                    await window.ethereum.request({
                                        method: 'wallet_addEthereumChain',
                                        params: [
                                            {
                                                /*chainName: 'Smart ChainChain',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
                                                rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

                                                /*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
                                                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

                                                /*chainName: 'zkSync alpha testnet',
                                                    chainId: web3.utils.toHex(chainId),
                                                    nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
                                                    rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

                                                chainName: 'zkSync Era Mainnet',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
                                                rpcUrls: ['https://mainnet.era.zksync.io'],
                                                /*chainName: 'Goerli Test Network',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
                                                rpcUrls: ['https://goerli.infura.io/v3/'],*/
                                            },
                                        ],
                                    });
                                }
                            }
                        }




                        try {

                            try {
                                localStorage.setItem('isWalletConnected', true);
                            } catch (ex) {
                                console.log(ex)
                            }

                            totalSupplyNFT = await contract.methods.totalSupply(0).call();
                            this.setState({ totalSupply: totalSupplyNFT });
                            console.log("Total Supply:" + totalSupplyNFT);

                            publicSale = await contract.methods.balanceOf(account, 0).call();
                            this.setState({ myNFTWallet: publicSale });

                            public_sale_cost = await contract.methods.publicSaleCost().call();
                            this.setState({ _public_sale_cost: public_sale_cost });
                            console.log("public_sale_cost :" + public_sale_cost);
                            this.setState({ _cost: public_sale_cost });
                            this.setState({ _mintStatus: false });

                            MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
                            this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
                            console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

                            public_mint_status = await contract.methods.public_mint_status().call();
                            this.setState({ _public_mint_status: public_mint_status });
                            console.log("public_mint_status :" + public_mint_status);



                            owner = await contract.methods.owner().call();
                            console.log("Owner" + owner);

                            if (owner == account) {
                                console.log("owner : " + owner)
                                onlyLeft = 41000 - totalSupplyNFT;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }

                                valueOfNFTs = mintAmount * 0;

                                wMintAmount = mintAmount;


                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            } else {
                                mintAmount = 1;

                                if (totalSupplyNFT == 41000) {

                                    onlyLeft = 0;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }
                                    this.setState({ msg: "SOLD OUT" });

                                } else {
                                    mintAmount = 1;
                                    onlyLeft = MAX_PER_WALLET - publicSale;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }

                                    valueOfNFTs = mintAmount * this.state._cost;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                }
                            }
                            ///................................///



                            //..........................................................................//

                        } catch (err) {
                            console.log("err: " + err);

                        }
                    }
                })();

                //.....................................................................//

                // Legacy providers may only have ethereum.sendAsync
                const chainId = await provider.request({
                    method: 'eth_chainId'
                })

            } else {

                // if the provider is not detected, detectEthereumProvider resolves to null
                console.error('Please install a Valid Wallet');
                alert('A valid provider could not be found!');

            }

        }

    }

    walletDisconnect = async event => {
        event.preventDefault();


        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "bf933c3446b3464c988114813a1360ac" // required
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });



        // disconnect wallet
        web3Modal.clearCachedProvider();
        window.location.reload(true);

        try {
            localStorage.setItem('isWalletConnected', false)
        } catch (ex) {
            console.log(ex)
        }

    }

    whitelistMint = async event => {
        event.preventDefault();


        //	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
        console.log("Root Hash: ", rootHash);
        console.log("Root HashHash: ", rootHashHash);

        // ***** ***** ***** ***** ***** ***** ***** ***** // 

        // CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
        // required to derive the root hash of the Merkle Tree

        // ✅ Positive verification of address
        //const claimingAddress = leafNodes[0];
        //console.log("Claiming Address:"+ claimingAddress);
        // ❌ Change this address to get a `false` verification
        const claimingAddress = keccak256(account);

        // `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
        // be required to derive the Merkle Trees root hash.
        const hexProof = merkleTree.getHexProof(claimingAddress);
        console.log("HexProof:" + hexProof);

        // ✅ - ❌: Verify is claiming address is in the merkle tree or not.
        // This would be implemented in your Solidity Smart Contract
        console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
        FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
        this.setState({ _FinalResult: FinalResult });

        if (FinalResult) {
            try {
                this.setState({ statusError: false, statusLoading: true });
                await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
                this.setState({ statusLoading: false, success: true });
                console.log("Mint Amount :" + this.state.wMintAmount);

            } catch (err) {
                this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                console.log(err);
            }
        } else {
            wlMsg = "Not Eligible for WL"
            this.setState({ _wlMsg: wlMsg });
        }

    }

    navbarOpen = async event => {
        event.preventDefault();

        this.setState({ _navbarOpen: 1 });
    }


    render() {
        return (

            <div>
                 
                <div class="mintMain">

                    <div>
                        <div class="glitch-wrapper">
                            <div class="glitch" data-text="MINTING SOON!">MINTING SOON!</div>
                           
                        </div>
                        <img class="back" onClick={() => window.location.href = '/'} src={back}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mint;

